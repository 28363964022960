import type { GetStaticProps, NextPage } from 'next'

import { trpc } from '~/api/client'
import { ssgHelper } from '~/api/ssrHelpers'
import { Home } from '~/client/Home/Home'
import { PacksDisplayListProvider, PacksDisplayListProps } from 'components'

const HomePage: NextPage<HomeProps> = () => {
  const query = trpc.useQuery(['soldPackData'])

  return (
    <PacksDisplayListProvider value={query.data}>
      <Home />
    </PacksDisplayListProvider>
  )
}
export default HomePage

export const getStaticProps: GetStaticProps = async () => {
  const ssr = await ssgHelper()
  await ssr.fetchQuery('soldPackData')
  return {
    props: {
      trpcState: ssr.dehydrate(),
    },
    revalidate: 5, // update every 5 seconds
  }
}

export interface HomeProps extends PacksDisplayListProps {}
