import { If, PackImageProd, PacksDisplayList } from 'components'
import { motion } from 'framer-motion'
import { useDeviceSize } from '~/client/utils/ScreenSizes'
import { InferQueryOutput } from '~/api/client'
import {
  BounceInOutAnimation,
  PackSelectionCopyAnimation,
  SlideInFromBottomAnimation,
  SlideInFromLeftAnimation,
} from '~/client/components/Animations'
import React from 'react'
import { cx, PackActionButton } from '../PacksList/PacksList'
import { IPack, ROUTE, Series } from '~/interfaces'
import { useRouter } from 'next/router'
import { Link } from '../Link'
import { useProdPackDetails } from './hooks';

export const ProdNfts = () => {
  const router = useRouter()
  const featuredPacksTextAnimation = useDeviceSize({
    initial: null,
    desktop: SlideInFromLeftAnimation,
    tablet: BounceInOutAnimation,
    mobile: BounceInOutAnimation,
  })

  if (featuredPacksTextAnimation === null) return null
  return (
    <div className={cx('container', 'packs-container')}>
      <If cond={router.pathname === ROUTE.home}>
        <motion.div
          variants={PackSelectionCopyAnimation}
          className={cx('packslist-copy-container')}
        >
          <ProdNftDescription />
        </motion.div>
      </If>
      <PacksDisplayList>
        <ProdPacks />
      </PacksDisplayList>
    </div>
  )
}

const ProdNftDescription = () => (
  <>
    <h2>&ldquo;What The Prod?&rdquo; NFT Packs</h2>
    <p>
      Series 2, contains 16 different moments from Paul Rodriguez&apos;s part in
      &apos;What The Prod?&apos;. Including the{' '}
      <strong className='legendary-text'>Legendary</strong> &{' '}
      <strong className='sweep'>Legendary Refractor</strong> NFT&apos;s which
      unlocks multiple tangible collectibles.
    </p>
  </>
)

export const ProdPacks = () => {
  const packsAnimation = useDeviceSize({
    initial: null,
    desktop: SlideInFromBottomAnimation,
    mobile: SlideInFromBottomAnimation,
    tablet: SlideInFromBottomAnimation,
  })

  const prodPackDetails = useProdPackDetails()
  
  if (packsAnimation === null) return null
  return (
    <>
      {prodPackDetails.data!.map((pack, i) => (
        <motion.div key={i} variants={packsAnimation}>
          <PackDisplayProd key={i} {...pack} />
        </motion.div>
      ))}
    </>
  )
}

export const PackDisplayProd = (props: InferQueryOutput<'series2.packTypeData'>[number],) => {
  return (
    <div className={cx('pack-display-container')}>
      <Link href={`/packs/${Series.two}/${props.name.toLowerCase()}`}>
        <PackImageProd type={props.name} />
      </Link>
      <div>
        <h2 className='pack-type'>{props.name} pack</h2>
        <p>What the P-Rod | Paul Rodriguez</p>
        <h3>${props.price / 100}</h3>
        <p className='is-small'>
          {props.available}/{props.total} Packs <br />
        </p>
      </div>
      <PackActionButton
          id={0}
          type={props.name}
          {...props}
          series={Series.two}
        />
    </div>
  )
}

// export const prodPacks: IPack[] = [
//   {
//     id: 1,
//     price: 14.69,
//     type: 'Standard',
//     available: 8299,
//     total: 11016,
//   },
//   {
//     id: 2,
//     price: 59.69,
//     type: 'Premium',
//     available: 2521,
//     total: 4020,
//   },
// ]
