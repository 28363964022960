import { Features, Roadmap } from '.'
import { PageTransitions } from '../components/Animations'
import s from './../../client/components/Tangibles/tangibles.module.scss'
import { withClassNames } from '~/styles'
import { MikeMoTangibles } from '../components/MikeMo/MikeMoTangibles'
import { ProdHeader } from '../components/Prod/ProdHeader'
import { Series3HeaderPacksList } from '../components/Series3'
import { JawsHeader } from '../components/Lock/JawsHeader'
import { TonyHeader } from '../components/Lock/TonyHeader'
import { If } from '../components'
import { useFeatureFlag } from '../components/Lock/utils'
import { JamieFoyTangible } from '../components/MikeMo/JamieFoyTangible'
import { JamieFoyVideoPartHeader } from '../components/Lock/JamieFoyVideoPartHeader'
import { CountdownHeader } from '../components/Lock/CountdownHeader'
export const cx = withClassNames(s)

export const Home = () => {
  const featureFlag = useFeatureFlag()
  return (
    <PageTransitions>
      <CountdownHeader />
      <TonyHeader />
      <br></br>
      <hr></hr>
      <JamieFoyTangible />
      <JamieFoyVideoPartHeader />
      <hr></hr>
      <br></br>
      <br></br>
      <JawsHeader />
      <div className='margin-bottom-20' />
      <hr></hr>
      <ProdHeader />
      <Features />
      <Series3HeaderPacksList />
      {/* <ProdNfts /> */}
      <MikeMoTangibles />
      <Roadmap />
    </PageTransitions>
  )
}
