import { withClassNames } from '~/styles'
import s from './roadmap.module.scss'
const cx = withClassNames(s)

export const Roadmap = () => {
  return (
    <div className={cx('roadmap-container')}>
      <div className={cx('copy-container')}>
        <h2>Roadmap</h2>

        <h3>Quarter 1 - 2023</h3>
        <ul>
          <li>Quickstrike #2 - Jaws at El toro</li>
          <li>Quickstrike #3 - Jamie Foy at Hollywood High 16</li>
        </ul>
        <h3>Quarter 2 - 2023</h3>
        <ul>
          <li>Quickstrike #4</li>
          <li>NFT Marketplace, buy, trade, and sell your NFT&apos;s.</li>
          <li>Series #4</li>
          <li>First in-person event</li>
          <li>
            NFT functionality of rewards, events, and limited product drops.
            Only accessible through holding certain NFT&apos;s.
          </li>
        </ul>
      </div>
    </div>
  )
}
