import classNames from 'classnames'
import { GradientBox } from '~/client/components/GradientBox/GradientBox'
import s from './features.module.scss'
import { motion } from 'framer-motion'
import { useDeviceSize } from '~/client/utils/ScreenSizes'
import { FadeIn, GradientBoxAnimation } from '~/client/components/Animations'
import { withClassNames } from '~/styles'
import { ProdMainTrailer } from '~/client/components'
const cx = withClassNames(s)

export const Features = () => {
  const gradientBoxAnimation = useDeviceSize({
    initial: null,
    desktop: GradientBoxAnimation,
    tablet: GradientBoxAnimation,
    mobile: FadeIn,
  })

  if (gradientBoxAnimation === null) return null

  return (
    <motion.div
      variants={gradientBoxAnimation}
      className={cx('container', 'gradient-container')}
    >
      <GradientBox>
        <div className={s.box}>
          <h3>Purchase</h3>
          <strong className='rainbow-text'>& Open Digital Packs</strong>
        </div>
        <div className={s.box}>
          <h3>Collect</h3>
          <strong className='rainbow-text'>
            Moments from famous skate videos
          </strong>
        </div>
        <div className={s.box}>
          <h3>Chance to win a</h3>
          <strong className='rainbow-text'>Signed Tangible Collectibles</strong>
        </div>
      </GradientBox>
    </motion.div>
  )
}
