import { FadeIn, If, Tangibles } from 'components'
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { useDeviceSize } from '~/client/utils/ScreenSizes'
import { Series } from '~/interfaces'
import { withClassNames } from '~/styles'
import { MailChimp } from '../MailChimp/MailChimp'
import s from '../Tangibles/tangibles.module.scss'
import {
  AllPacksExpireBox,
  AvailableText,
  Countdown,
  PleaseWait,
  SoldOutGradientBox,
} from '../Countdown/CountDown'
import { useProdReleaseFeatureFlag } from '~/client/utils/prodReleaseFeatureFlag'
import { trpc } from '~/api/client'
const cx = withClassNames(s)

export const ProdHeader = ({ showButton = true }: IProdTangible) => (
  <Tangibles series={Series.two}>
    <ProdHeaderContent showButton={showButton} />
  </Tangibles>
)

export const ProdHeaderContent = ({ showButton }: IProdTangible) => {
  const [timerDone, setTimerDone] = useState(true)
  const prodRelease = useProdReleaseFeatureFlag()
  const soldPackData = trpc.useQuery(['soldPackData'])
  // TODO: dear god this is gross, I hate myself for even vomitting it up. Apologies from the past!
  const isSoldOut =
    (soldPackData.data?.packs || []).reduce(
      (sum, pack) => sum + (pack.available || 0),
      0,
    ) > 0

  const copyAnimation = useDeviceSize({
    initial: null,
    desktop: FadeIn,
    mobile: FadeIn,
    tablet: FadeIn,
  })

  useEffect(() => {
    setTimerDone(false)
  }, [])

  return (
    <motion.div
      variants={copyAnimation}
      className={cx('collectibles-text-container')}
    >
      <h4>Series 2</h4>
      <h1>What The Prod? Tangible&nbsp;&&nbsp;Digital&nbsp;Collectibles</h1>
      <h4 className={cx('description')}>
        Authentic Collectibles By Paul Rodriguez
      </h4>
      <AllPacksExpireBox />
    </motion.div>
  )
}

interface IProdTangible {
  showButton?: boolean
}
