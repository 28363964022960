import { If, MailChimp, ButtonLink } from '~/client/components'
import { motion } from 'framer-motion'
import { withClassNames } from '~/styles'
import s from './lock.module.scss'
import { useDeviceSize } from '~/client/utils/ScreenSizes'
import {
  BounceInOutAnimation,
  HeaderAnimationVariants,
  SlideInFromRightAnimation,
  SlideInFromLeftAnimation,
} from '~/client/components/Animations'
import { useFeatureFlag } from './utils'
import { CountdownTimer } from '../Countdown/CountDownBase'

const cx = withClassNames(s)

export const JawsHeader = () => {
  const { copyAnimation, videoAnimation } = useAnimations()
  const featureFlag = useFeatureFlag()

  if (copyAnimation === null) return null

  return (
    <div className={cx('header-main')}>
      <motion.div
        variants={HeaderAnimationVariants}
        className={cx(
          'header-container',
        )}
      >
          <motion.section variants={videoAnimation}>
            <YouTubeEmbed />
          </motion.section>

          <motion.div variants={copyAnimation} className={cx('copy')}>
            <h1>Jaws at El Toro</h1>

            <If cond={!featureFlag.jawsLive}>
              <p className="centered">Signed & Numbered Collectibles by Aaron "Jaws" Homoki</p>
              <If cond={featureFlag.allAvailable}>
                <h3 className='margin-10 centered'>Available today only</h3>
                <ButtonLink className={'pulsingButton margin-10'} href={'https://store.abdcollectibles.com/products/jaws-at-el-toro'}>
                  Shop now
                </ButtonLink>
              </If>
              <If cond={!featureFlag.allAvailable}>
                <h3 className='margin-10 centered'>Available only on April 20th</h3>
              </If>

              <If cond={!featureFlag.earlyAccess}>
              <CountdownTimer countdownDate={'January 14, 2023 18:00:00 GMT-0800'}/>
              </If>

              <If cond={!featureFlag.allowingAccess && !featureFlag.earlyAccess && !featureFlag.allAvailable}>
                  <MailChimp className={cx('container')} />
              </If>

              <If cond={featureFlag.allowingAccess && !featureFlag.earlyAccess}>
                  <div className="centered margin-20">
                  <button disabled className='outline'>
                    <p>I will automatically turn into a button, shortly.</p>
                    <div className='loading-dots'></div>
                  </button>
                  <br></br>
                  <br></br>
                  <p>Do not refresh</p>
                  </div>
              </If>
            </If>

            <If cond={featureFlag.jawsLive}>
              <br></br>
              <h2 className='center'>OUT NOW!</h2>
              <p className="center">All cards will be vaulted and locked away at 9pm PST</p>
              <br></br>
              <ButtonLink className={'pulsingButton'} href={'https://store.abdcollectibles.com/products/jaws-at-el-toro'}>
                Shop now
              </ButtonLink>
            </If>

          </motion.div>
      </motion.div>
    </div>
  )
}

function useAnimations() {
  const copyAnimation = useDeviceSize({
    initial: null,
    desktop: SlideInFromLeftAnimation,
    tablet: SlideInFromLeftAnimation,
    mobile: BounceInOutAnimation,
  })

  const videoAnimation = useDeviceSize({
    initial: null,
    desktop: SlideInFromRightAnimation,
    tablet: SlideInFromRightAnimation,
    mobile: BounceInOutAnimation,
  })
  return { copyAnimation, videoAnimation }
}

const YouTubeEmbed = () => (
  <div className={cx('youtube-embed')}>
    <iframe
      style={{ height: '100%', width: '100%' }}
      src='https://www.youtube.com/embed/sTL9FHcKN0U'
      title='YouTube video player'
      frameBorder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
    ></iframe>
  </div>
)

const TwitchEmbed = () => (
  <div className={cx('twitch-embed')}>
    <iframe
      style={{ height: '100%', width: '100%' }}
      src={
        'https://player.twitch.tv/?channel=abdcollectibles&parent=' +
        window.location.hostname
      }
      frameBorder='0'
      allowFullScreen
      scrolling='no'
    ></iframe>
  </div>
)
