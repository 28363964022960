import { ButtonLink, If } from '~/client/components'
import { ToreyMainTrailer } from '~/client/components/Videos'
import { motion } from 'framer-motion'
import { withClassNames } from '~/styles'
import s from './lock.module.scss'
import { useDeviceSize } from '~/client/utils/ScreenSizes'
import {
  BounceInOutAnimation,
  SlideInFromRightAnimation,
  SlideInFromLeftAnimation,
} from '~/client/components/Animations'
import { useFeatureFlag } from './utils'
import { CountdownTimer } from '../Countdown/CountDownBase'

const cx = withClassNames(s)

export const CountdownHeader = () => {
  const { copyAnimation } = useAnimations()
  const featureFlag = useFeatureFlag()

  if (copyAnimation === null) return null

  return (
    <div className={cx('header-main', 'background-gradient', 'centered')}>
      <motion.div>
        <If cond={!featureFlag.allAvailable}>
            <h3 className='margin-10'>Celebrating 1 Year of ABD!</h3>
            <div className='margin-10'><strong>All Cards and Digital Packs will be available on April 20th</strong></div>
          <CountdownTimer countdownDate={'April 20, 2023 00:00:00 GMT-0800'}/>
        </If>

        <If cond={featureFlag.allAvailable}>
            <h3 className='margin-10'>Celebrating 1 Year of ABD!</h3>
            <div className='margin-10'><strong>All Cards and Digital Packs are available today only.</strong></div>
          <CountdownTimer countdownDate={'April 21, 2023 00:00:00 GMT-0800'}/>
        </If>
        {/* <ButtonLink className={'discord-button'} href={'https://discord.gg/Vef2694HRa'}>
          Join Discord
        </ButtonLink> */}
      </motion.div>
    </div>
  )
}

function useAnimations() {
  const copyAnimation = useDeviceSize({
    initial: null,
    desktop: SlideInFromLeftAnimation,
    tablet: SlideInFromLeftAnimation,
    mobile: BounceInOutAnimation,
  })

  const videoAnimation = useDeviceSize({
    initial: null,
    desktop: SlideInFromRightAnimation,
    tablet: SlideInFromRightAnimation,
    mobile: BounceInOutAnimation,
  })
  return { copyAnimation, videoAnimation }
}

const YouTubeEmbed = () => (
  <div className={cx('youtube-embed')}>
    <ToreyMainTrailer />
    {/* <iframe
      style={{ height: '100%', width: '100%' }}
      src='https://www.youtube.com/embed/0kKJXZ4Ufk4'
      title='YouTube video player'
      frameBorder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
    ></iframe> */}
  </div>
)
