import { InferQueryOutput } from '~/api/client'
import {
  ButtonLink,
  If,
  Link,
  PacksDisplayList,
  PackSelectionCopyAnimation,
} from '../index'
import { ToreyPackImage } from './ToreyPack'
import { cx, PackActionButton } from '~/client/components/PacksList/PacksList'
import { ROUTE, Series } from '~/interfaces'
import { useToreyPackDetails } from './hooks'
import { motion } from 'framer-motion'
import { useRouter } from 'next/router'

export const ToreyNfts = () => {
  const packData = useToreyPackDetails()
  const router = useRouter()

  return (
    <div className={cx('container', 'packs-container')}>
      <If cond={router.pathname === ROUTE.home}>
        <motion.div
          variants={PackSelectionCopyAnimation}
          className={cx('packslist-copy-container')}
        >
          <ToreyNftDescription />
        </motion.div>
      </If>
      <PacksDisplayList>
        {packData.data!.map((pack) => (
          <ToreyPackDetails key={pack.image} {...pack} />
        ))}
      </PacksDisplayList>
    </div>
  )
}

export const ToreyPackDetails = (
  props: InferQueryOutput<'series3.packTypeData'>[number],
) => {
  const router = useRouter()
  const showButton = !router.pathname.includes('waiting-room')
  return (
    <div className={cx('pack-display-container')}>
        <ToreyPackImage src={props.image!} />
      {/* <Link href={`/packs/${Series.three}/${props.name.toLowerCase()}`}>
      </Link> */}
      <div>
        <h2 className={cx('pack-type')}>{props.name} pack</h2>
        <p className={cx('torey-title')}>Bigger Bang | Torey Pudwill</p>
        <h3>${props.price / 100}</h3>
        <p className={cx('is-small')}>
          {props.available}/{props.total} Packs
        </p>
      </div>
      {/* {showButton && (
        <PackActionButton
          id={0}
          type={props.name}
          {...props}
          series={Series.three}
        />
      )} */}
      <ButtonLink className={'margin-auto'} href={''}>
        Unavailable
      </ButtonLink>
    </div>
  )
}

export const ToreyNftDescription = () => (
  <>
    <h2>&ldquo;Bigger Bang!&rdquo; Digital Packs</h2>
    <p>
      Series 3, contains 36 different moments from Torey Pudwill&apos;s part in
      &apos;Bigger Bang!&apos;. Including the{' '}
      <strong className='sweep'>Legendary Refractor</strong> NFT which unlocks
      signed and numbered tangible collectible.
    </p>
  </>
)
