import { FadeIn } from 'components'
import { motion } from 'framer-motion'
import { useDeviceSize } from '~/client/utils/ScreenSizes'
import { withClassNames } from '~/styles'
import { MailChimp } from '../MailChimp/MailChimp'
import { ProdNfts } from '../Prod'
import s from '../Series3/series-3-header.module.scss'
const cx = withClassNames(s)

export const Series3Header = ({
  showButton = true,
  blurryImage,
}: IProdTangible) => (
  <Series3HeaderContent showButton={showButton} blurryImage={blurryImage} />
)

export const Series3HeaderPacksList = () => {
  return (
    <div className={cx('')}>
      <ProdNfts />
    </div>
  )
}

export const Series3HeaderContent = ({ blurryImage }: IProdTangible) => {
  const copyAnimation = useDeviceSize({
    initial: null,
    desktop: FadeIn,
    mobile: FadeIn,
    tablet: FadeIn,
  })

  return (
    <motion.div variants={copyAnimation} className='center'>
      <div className='tv-and-bg'>
        <img
          className='series3-tease-img'
          src={`/series-3-blurry/${blurryImage}`}
        ></img>
        <div className='element'></div>
      </div>
      <div className='mailchimp-width'>
        <h1>Series 3</h1>
        <h1>Official announcement</h1>
        <h2>October 21st at 7PM</h2>
        <MailChimp />
      </div>
    </motion.div>
  )
}

interface IProdTangible {
  showButton?: boolean
  blurryImage: string
}
