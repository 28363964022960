import { If, MailChimp, ButtonLink } from '~/client/components'
import { TonyHawkVaulted } from '~/client/components/Videos'
import { motion } from 'framer-motion'
import { withClassNames } from '~/styles'
import s from './lock.module.scss'

import { useDeviceSize } from '~/client/utils/ScreenSizes'
import {
  BounceInOutAnimation,
  HeaderAnimationVariants,
  SlideInFromRightAnimation,
  SlideInFromLeftAnimation,
} from '~/client/components/Animations'
import { useFeatureFlag } from './utils'
import { Countdown } from '../Countdown/CountDownBase'

const cx = withClassNames(s)

export const TonyHeader = () => {
  const { copyAnimation, videoAnimation } = useAnimations()
  const featureFlag = useFeatureFlag()

  if (copyAnimation === null) return null

  return (
    <div className={cx('header-main')}>
      <motion.div
        variants={HeaderAnimationVariants}
        className={cx(
          'header-container',
        )}
      >
      <motion.div variants={copyAnimation} className={cx('copy')}>
          <h1>Tony Hawk's Last 900</h1>
          <p className="centered">Signed & Numbered Collectibles by Tony Hawk</p>
          <If cond={featureFlag.allAvailable}>
            <h3 className='margin-10 centered'>Available today only</h3>
            <ButtonLink className={'pulsingButton margin-10'} href={'https://store.abdcollectibles.com/products/last-900'}>
              Shop now
            </ButtonLink>
          </If>
          <If cond={!featureFlag.allAvailable}>
            <h3 className='margin-10 centered'>Available only on April 20th</h3>
            <MailChimp className={cx('container')} />
          </If>

          <If cond={featureFlag.notActiveDrop}>
            <If cond={!featureFlag.tonyHawkLive}>
                <If cond={!featureFlag.earlyAccess}> 
                    <p className="centered margin-top-20">This card has been vaulted, sign up for the next drop window</p>

                    <If cond={!featureFlag.allowingAccess}>
                      <MailChimp className={cx('container')} />
                    </If>

                    <If cond={featureFlag.allowingAccess && !featureFlag.earlyAccess}>
                      <div className="centered margin-20">
                      <button disabled className='outline'>
                        <p>I will automatically turn into a button, shortly.</p>
                        <div className='loading-dots'></div>
                      </button>
                      <br></br>
                      <br></br>
                      <p>Do not refresh</p>
                      </div>
                    </If>
                </If>
                <If cond={featureFlag.earlyAccess}>
                    <h3 className='center margin-20'>Early Access Granted</h3>
                    <Countdown countdownDate={'January 10, 2023 17:30:00 GMT-0800'}/>
                    <ButtonLink className={'pulsingButton'} href={'https://store.abdcollectibles.com/products/last-900'}>
                      Shop now
                    </ButtonLink>
                </If>
            </If>

            <If cond={featureFlag.tonyHawkLive}>
              <br></br>
              <h2 className='center'>OUT NOW!</h2>
              <p className="center">All cards will be vaulted and locked away at 9pm PST</p>
              <br></br>
              <ButtonLink className={'pulsingButton'} href={'https://store.abdcollectibles.com/products/last-900'}>
                Shop now
              </ButtonLink>
            </If>
          </If>

        </motion.div>

        <motion.section variants={videoAnimation}>
          <TonyHawkVaulted />
        </motion.section>
      </motion.div>
    </div>
  )
}

function useAnimations() {
  const copyAnimation = useDeviceSize({
    initial: null,
    desktop: SlideInFromLeftAnimation,
    tablet: SlideInFromLeftAnimation,
    mobile: BounceInOutAnimation,
  })

  const videoAnimation = useDeviceSize({
    initial: null,
    desktop: SlideInFromRightAnimation,
    tablet: SlideInFromRightAnimation,
    mobile: BounceInOutAnimation,
  })
  return { copyAnimation, videoAnimation }
}

const YouTubeEmbed = () => (
  <div className={cx('youtube-embed')}>
    {/* <iframe
      style={{ height: '100%', width: '100%' }}
      src='https://www.youtube.com/embed/0kKJXZ4Ufk4'
      title='YouTube video player'
      frameBorder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
    ></iframe> */}
  </div>
)

const TwitchEmbed = () => (
  <div className={cx('twitch-embed')}>
    <iframe
      style={{ height: '100%', width: '100%' }}
      src={
        'https://player.twitch.tv/?channel=abdcollectibles&parent=' +
        window.location.hostname
      }
      frameBorder='0'
      allowFullScreen
      scrolling='no'
    ></iframe>
  </div>
)
