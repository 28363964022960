import { If } from '~/client/components'
import { motion } from 'framer-motion'
import { withClassNames } from '~/styles'
import s from './lock.module.scss'
import { useDeviceSize } from '~/client/utils/ScreenSizes'
import {
  BounceInOutAnimation,
  HeaderAnimationVariants,
  SlideInFromRightAnimation,
  SlideInFromLeftAnimation,
} from '~/client/components/Animations'
import { useFeatureFlag } from './utils'

const cx = withClassNames(s)

export const JamieFoyVideoPartHeader = () => {
  const { copyAnimation, videoAnimation } = useAnimations()
  const featureFlag = useFeatureFlag()

  if (copyAnimation === null) return null

  return (
    <div className={cx('header-main', 'glitch-background')}>
      <motion.div
        variants={HeaderAnimationVariants}
        className={cx(
          'header-container',
          !!featureFlag.displayVideos && 'videos-container',
        )}
      >
        <If cond={!featureFlag.displayVideos}>
          <motion.div variants={copyAnimation} className={cx('copy')}>
            <h1>Watch the story of <br></br>Jamie Foy at Hollywood High</h1>
          </motion.div>

          <motion.section variants={videoAnimation}>
            <YouTubeEmbed />
          </motion.section>
        </If>

        <If cond={featureFlag.displayVideos}>
          <motion.section variants={videoAnimation}>
            <YouTubeEmbed />
          </motion.section>
          <motion.section variants={videoAnimation}>
            <TwitchEmbed />
          </motion.section>
        </If>
      </motion.div>
    </div>
  )
}

function useAnimations() {
  const copyAnimation = useDeviceSize({
    initial: null,
    desktop: SlideInFromLeftAnimation,
    tablet: SlideInFromLeftAnimation,
    mobile: BounceInOutAnimation,
  })

  const videoAnimation = useDeviceSize({
    initial: null,
    desktop: SlideInFromRightAnimation,
    tablet: SlideInFromRightAnimation,
    mobile: BounceInOutAnimation,
  })
  return { copyAnimation, videoAnimation }
}

const YouTubeEmbed = () => (
  <div className={cx('youtube-embed')}>
    <iframe
      style={{ height: '100%', width: '100%' }}
      src='https://www.youtube.com/embed/PyLOk8i6gNw'
      title='YouTube video player'
      frameBorder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
    ></iframe>
  </div>
)

const TwitchEmbed = () => (
  <div className={cx('twitch-embed')}>
    <iframe
      style={{ height: '100%', width: '100%' }}
      src={
        'https://player.twitch.tv/?channel=abdcollectibles&parent=' +
        window.location.hostname
      }
      frameBorder='0'
      allowFullScreen
      scrolling='no'
    ></iframe>
  </div>
)
