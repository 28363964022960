import { Tangibles } from 'components'
import { FadeIn } from '../Animations'
import { Series } from '~/interfaces'
import { motion } from 'framer-motion'
import { useDeviceSize } from '../../utils/ScreenSizes'
import { withClassNames } from '~/styles'
import s from './../Tangibles/tangibles.module.scss'
const cx = withClassNames(s)

export const MikeMoTangibles = () => (
  <Tangibles series={Series.one}>
    <MikeMoTangible />
  </Tangibles>
)
const MikeMoTangible = ({ showButton = true }) => {
  const copyAnimation = useDeviceSize({
    initial: null,
    desktop: FadeIn,
    mobile: FadeIn,
    tablet: FadeIn,
  })

  if (copyAnimation === null) return null

  return (
    <motion.div
      variants={copyAnimation}
      className={cx('collectibles-text-container')}
    >
      <h4>Unlock the</h4>
      <h1>Mikemo Capaldi Signed & Numbered Tangible&nbsp;Collectible</h1>
      <h4>
        Fully Flared packs include the chance to pull the{' '}
        <strong className={'legendary-text'}>Legendary NFT</strong>, which will
        unlock the Tangible Collectible of Mikemo Capaldi&apos;s Switch Flip
        Explosion for free.
      </h4>
      {showButton && <button>SOLD OUT</button>}
    </motion.div>
  )
}
