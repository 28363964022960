import { AuthButton, AuthButtonAlternate, ButtonLink, If, MailChimp, Tangibles } from 'components'
import { FadeIn } from '../Animations'
import { Series } from '~/interfaces'
import { motion } from 'framer-motion'
import { useDeviceSize } from '../../utils/ScreenSizes'
import { withClassNames } from '~/styles'
import s from './../Tangibles/tangibles.module.scss'
import { useFeatureFlag } from '../Lock/utils'
import { CountdownTimer } from '../Countdown/CountDown'
const cx = withClassNames(s)

export const JamieFoyTangible = () => (
  <Tangibles series={Series.jamieFoy}>
    <JamieTangible />
  </Tangibles>
)
const JamieTangible = ({ showButton = true }) => {
  const featureFlag = useFeatureFlag()
  const copyAnimation = useDeviceSize({
    initial: null,
    desktop: FadeIn,
    mobile: FadeIn,
    tablet: FadeIn,
  })

  if (copyAnimation === null) return null

  return (
    <motion.div
      variants={copyAnimation}
      className={cx('collectibles-text-container')}
    >
      <If cond={!featureFlag.foyLive}>
        <p>Quickstrike #3</p>
        <h3 className='margin-10'>Jamie Foy Fakie 5-0 Hollywood High 16</h3>
        <p className="centered">Signed, numbered, and includes a piece of Jamie's truck.</p>
        <If cond={featureFlag.allAvailable}>
          <h3 className='margin-10'>Available today only</h3>
          <ButtonLink className={'pulsingButton'} href={'https://store.abdcollectibles.com/products/jamie-foy-at-hollywood-high-16'}>
            Shop now
          </ButtonLink>
        </If>
        <If cond={!featureFlag.allAvailable}>
          <h3 className='margin-10'>Available only on April 20th</h3>
        </If>
        <If cond={!featureFlag.earlyAccess && !featureFlag.allowingAccess}>
          <If cond={!featureFlag.earlyAccessActive}>
            <CountdownTimer countdownDate={'March 11, 2023 18:00:00 GMT-0800'}/>
          </If>
          <If cond={featureFlag.earlyAccessActive}>
          <button disabled className='outline'>
            <h3>Early access in progress</h3>
            <span className='loading-dots'></span>
            <p>Sign in to your account and if you are holding any digital collectibles, access will be granted.</p>
            <AuthButtonAlternate />
            </button>
          </If>
          <If cond={!featureFlag.earlyAccessActive && !featureFlag.allAvailable}>
            <MailChimp />
          </If>
        </If>
        <If cond={featureFlag.earlyAccess && !featureFlag.allowingAccess}>
          <h3 className='center'>Early Access Granted</h3>
          <ButtonLink className={'pulsingButton'} href={'https://store.abdcollectibles.com/products/jamie-foy-at-hollywood-high-16'}>
            Shop now
          </ButtonLink>
        </If>
        <If cond={featureFlag.allowingAccess}>
          <button disabled className='outline'>
            <h3>Public access begins now</h3>
            <p>I will automatically turn into a button, shortly.</p>
            <p>Do not refresh</p>
            <div className='loading-dots'></div>
          </button>
        </If>
      </If>
      
      <If cond={featureFlag.foyLive}>
        <p>Quickstrike #3</p>
        <h3 className='margin-10'>Jamie Foy Fakie 5-0 Hollywood High 16</h3>
        <p>
          Signed & numbered cards, with a piece of Jamie Foy's truck.
        </p>
        <h3 className='margin-10'>OUT NOW</h3>
        <ButtonLink className={'pulsingButton'} href={'https://store.abdcollectibles.com/products/jamie-foy-at-hollywood-high-16'}>
          Shop now
        </ButtonLink>
      </If>
    </motion.div>
  )
}
