import { ButtonLink, If } from '~/client/components'
import { motion } from 'framer-motion'
import { withClassNames } from '~/styles'
import s from './header.module.scss'

import { useDeviceSize } from '~/client/utils/ScreenSizes'
import {
  BounceInOutAnimation,
  HeaderAnimationVariants,
  SlideInFromRightAnimation,
  SlideInFromLeftAnimation,
} from '~/client/components/Animations'
import { Countdown } from '~/client/components/Countdown/CountDown'
import { IfFeatureEnabled } from '@growthbook/growthbook-react'
import { IfFeatureDisabled } from '~/analytics/growthbook/react'

const cx = withClassNames(s)

export const Header = () => {
  const isMobile = useDeviceSize({
    desktop: false,
    initial: false,
    mobile: true,
    tablet: false,
  })

  const copyAnimation = useDeviceSize({
    initial: null,
    desktop: SlideInFromLeftAnimation,
    tablet: SlideInFromLeftAnimation,
    mobile: BounceInOutAnimation,
  })

  const videoAnimation = useDeviceSize({
    initial: null,
    desktop: SlideInFromRightAnimation,
    tablet: SlideInFromRightAnimation,
    mobile: BounceInOutAnimation,
  })

  if (copyAnimation === null) return null

  return (
    <div className={cx('header-main')}>
      <motion.div
        variants={HeaderAnimationVariants}
        className={cx('header-container', { [s.banner]: !isMobile })}
      >
        <motion.section
          variants={videoAnimation}
          className={cx({ [s.banner]: isMobile })}
        >
          <If cond={isMobile}>
            <img src={'/series-3-banner-mobile.jpg'} />
          </If>
        </motion.section>
        <motion.div variants={copyAnimation} className={cx('copy')}>
          <h2 className='center'>SERIES 3</h2>
          <div className={cx('title-text')}>
            <h2 className={cx('center', 'title-text', 'title-text-1')}>
              Torey Pudwill "Bigger Bang"
            </h2>
            <h2 className={cx('center', 'title-text', 'title-text-2')}>
              digital and tangible collectibles
            </h2>
          </div>

          <IfFeatureEnabled feature='sale_ended'>
            <ButtonLink href={'/packs'} className={cx('center')}>
              Pack Sales Ended
            </ButtonLink>
          </IfFeatureEnabled>

          <IfFeatureDisabled feature='sale_ended'>
            <p className={cx('center')}>Pack sale ends in</p>
            <div className={cx('countdown')}>
              <Countdown />
            </div>
            <ButtonLink href={'/packs'} className='pulsingButton'>
              Start your collection
            </ButtonLink>
          </IfFeatureDisabled>
        </motion.div>
      </motion.div>
    </div>
  )
}
